import { useState, useEffect } from 'react';
import {
  Navbar,
  Container,
  Image,
  Button,
  Dropdown,
  Modal,
} from 'react-bootstrap';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { Logo } from '../../assets/image';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/type';
import { getInitials } from '../../utilities/helper';
import { logoutService } from '../../services/auth';
import { getAccessToken } from '../../utilities/getAccessToken';
import { setSelectedTab } from '../../redux/slices/sideMenu/sideMenuSlice';
import React from 'react';
import { ROUTES } from '../../routes/routes';
import { useTranslation } from 'react-i18next';
import { currentUserService } from '../../services/user/currentUserService';
import { setUserReducer } from '../../redux/slices/auth/authSlice';

const authPages = [
  ROUTES.LOGIN,
  ROUTES.REGISTER,
  ROUTES.ACTIVATE_ACCOUNT,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.SUITABILITY_QUESTIONNAIRE,
];

function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = getAccessToken();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (token && !user) {
      currentUserService().then((result: any) => {
        if (result) {
          dispatch(setUserReducer({ user: result }));
        }
      });
    }
  }, []);

  function handleShowMenu() {
    setShowMenu(true);
  }

  function handleCloseMenu() {
    setShowMenu(false);
  }

  const location = useLocation();

  const handleNavigation = (url) => {
    // This forces a reload when navigating to the same URL
    if (window.location.pathname === url) {
      window.location.reload();
    } else {
      window.location.href = url;
    }
  };

  const handleLogout = () => {
    logoutService().then((res: any) => {
      if (res?.success) {
        navigate(ROUTES.LOGIN);
      }
    });
  };

  return (
    <header className={'sticky-top bg-light ' + props.className}>
      <Container className="border border-sm-0 text-nowrap">
        <Navbar
          bg="white"
          variant="light"
          className="py-0 d-flex justify-content-between px-md-16 px-4"
        >
          <Navbar.Brand onClick={() => navigate('/')} className="py-4">
            <Image
              alt=""
              src={Logo}
              width="160"
              style={{ maxWidth: '160px', cursor: 'pointer' }}
            />
          </Navbar.Brand>
          {!authPages.includes(location.pathname) &&
            !location.pathname?.includes('/activate-account/') && (
              <React.Fragment>
                <div className="d-md-flex gap-8 d-none">
                  <span>
                    <Link to={ROUTES.ABOUT_US} style={{ color: '#726f6f' }}>
                      {t('navbar.about_us')}
                    </Link>
                  </span>
                  <span>
                    <Link to={ROUTES.OUR_TEAM} style={{ color: '#726f6f' }}>
                      {t('navbar.our_team')}
                    </Link>
                  </span>
                  <span>
                    <Link to={ROUTES.FAQ} style={{ color: '#726f6f' }}>
                      {t('navbar.faqs')}
                    </Link>
                  </span>
                  <span>
                    <Link to={ROUTES.CONTACT_US} style={{ color: '#726f6f' }}>
                      {t('navbar.contact_us')}
                    </Link>
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  {!token ? (
                    <div className="d-flex gap-6">
                      <Link
                        to={ROUTES.REGISTER}
                        className="font-semibold text-dark d-sm-flex d-none justify-content-center align-items-center"
                      >
                        {t('navbar.register')}
                      </Link>
                      <Button
                        onClick={() => navigate(ROUTES.LOGIN)}
                        variant="dark"
                        className="rounded-pill btn-lg text-sm font-semibold w-full px-sm-7 py-sm-2 px-5 py-1"
                      >
                        {t('navbar.login')}
                      </Button>
                    </div>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        className="rounded-circle w-11 h-11 profile-button header-button d-flex align-items-center me-2"
                        id="dropdown-basic"
                      >
                        <div className="w-8 h-8 rounded-circle d-flex align-items-center justify-content-center font-semibold">
                          {getInitials(
                            user?.firstName || '',
                            user?.lastName || '',
                          )}
                        </div>
                        <span className="me-2 d-md-none d-none">{`${
                          user?.firstName || ''
                        } ${user?.lastName || ''}`}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="profile-menu  right-corner-menu">
                        {/* <Dropdown.Item className="font-bold">Dashboard</Dropdown.Item> */}
                        <Dropdown.Item
                          className="font-bold"
                          onClick={() => {
                            dispatch(
                              setSelectedTab({ selectedTab: 'Prop Offerings' }),
                            );
                            navigate(ROUTES.PROP_OFFERING);
                          }}
                        >
                          {t('navbar.prop_offerings')}
                        </Dropdown.Item>
                        {/* <Dropdown.Item className="font-bold" onClick={()=>navigate("/prop-offering")}>Messages</Dropdown.Item> */}
                        {/* <Dropdown.Item className="font-bold" onClick={()=>navigate("/prop-offering")}>Portfolio</Dropdown.Item> */}
                        <Dropdown.Item
                          className="font-bold"
                          onClick={() => {
                            dispatch(
                              setSelectedTab({ selectedTab: 'Favourites' }),
                            );
                            navigate(ROUTES.FAVOURITES);
                          }}
                        >
                          {t('navbar.favourites')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="font-bold"
                          onClick={() => {
                            dispatch(setSelectedTab({ selectedTab: 'Wallet' }));
                            navigate(ROUTES.WALLET);
                          }}
                        >
                          {t('navbar.wallet')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="font-bold"
                          onClick={() => {
                            dispatch(
                              setSelectedTab({ selectedTab: 'Account' }),
                            );
                            navigate(ROUTES.ACCOUNT);
                          }}
                        >
                          {t('navbar.account')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="font-bold"
                          style={{ color: 'red' }}
                          onClick={handleLogout}
                        >
                          {t('navbar.logout')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  <div
                    onClick={handleShowMenu}
                    className="ms-4 d-md-none d-block"
                  >
                    <AiOutlineMenu className="text-lg" />
                  </div>
                </div>
                <Modal
                  animation={false}
                  show={showMenu}
                  fullscreen={true}
                  onHide={() => setShowMenu(false)}
                >
                  <Modal.Body className="h-screen d-flex align-items-center">
                    <div className="text-center w-screen">
                      <div
                        onClick={() => handleNavigation('/')}
                        className="full-menu-item text-xl mb-7 text-black"
                      >
                        {t('navbar.home')}
                      </div>
                      <div
                        onClick={() => handleNavigation(ROUTES.ABOUT_US)}
                        className="full-menu-item text-xl mb-7 text-black"
                      >
                        {t('navbar.about_us')}
                      </div>
                      <div
                        onClick={() => handleNavigation(ROUTES.OUR_TEAM)}
                        className="full-menu-item text-xl mb-7 text-black"
                      >
                        {t('navbar.our_team')}
                      </div>
                      <div
                        onClick={() => handleNavigation(ROUTES.FAQ)}
                        className="full-menu-item text-xl mb-7 text-black"
                      >
                        {t('navbar.faqs')}
                      </div>
                      <div
                        onClick={() => handleNavigation(ROUTES.CONTACT_US)}
                        className="full-menu-item text-xl mb-7 text-black"
                      >
                        {t('navbar.contact_us')}
                      </div>
                    </div>
                    <div
                      onClick={handleCloseMenu}
                      className="rounded-circle bg-gray-200 p-4"
                      style={{
                        position: 'absolute',
                        bottom: 100,
                        left: '44%',
                      }}
                    >
                      <AiOutlineClose size={25} color="black" />
                    </div>
                  </Modal.Body>
                </Modal>
              </React.Fragment>
            )}
        </Navbar>
      </Container>
    </header>
  );
}

export default Header;
