import './sidebar.scss';
import { Container, Image, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { Logo } from '../../assets/image';
import {
  MdOutlineHomeWork,
  MdOutlineInsertChart,
  MdStarBorder,
} from 'react-icons/md';
import { PiPiggyBank } from 'react-icons/pi';
import { BiUser } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/type';
import { setFavouritesFlag } from '../../redux/slices/property/propertiesSlice';
import { setSelectedTab } from '../../redux/slices/sideMenu/sideMenuSlice';
import { ROUTES } from '../../routes/routes';
import { useState } from 'react';

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideMenu = useSelector((state: RootState) => state.sideMenu);

  return (
    <Navbar
      expand="lg"
      className="navbar-customization sec-scrollable navbar-vertical h-lg-screen py-0 px-0 scrollbar d-md-block d-none"
    >
      <Container fluid className="border-bottom">
        <Navbar.Brand className="py-3 px-3 sticky-top border-end bg-white">
          <div
            onClick={() => navigate('/')}
            className="h-11 hstack justify-content-center cursor-pointer"
          >
            <Image
              alt=""
              src={Logo}
              width="169"
              height="26"
              className="h-auto"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          className="overflow-auto main-siderbar"
          style={{ maxWidth: '80vw' }}
        >
          <Offcanvas.Header closeButton className="p-3 mx-3">
            <Offcanvas.Title id="offcanvasNavbarLabel">
              <Image
                className="h-11 order-2"
                src={Logo}
                width="169"
                height="26"
                alt="algradene logo"
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Navbar.Collapse
            id="basic-sidebar"
            className="border-top border-end side-navbar"
          >
            <Nav>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    dispatch(setFavouritesFlag({ isFavouritesTab: false }));
                    navigate(ROUTES.PROP_OFFERING);
                    dispatch(setSelectedTab({ selectedTab: 'Prop Offerings' }));
                  }}
                  className={`gap-3 nav-item ${
                    sideMenu?.selectedTab === 'Prop Offerings' ? 'active' : ''
                  }`}
                >
                  <MdOutlineHomeWork className="text-lg" /> Prop Offerings
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    navigate("/messages");
                    setSelectedTab(3);
                  }}
                  className={`d-flex align-items-center gap-3 ${selectedTab === 3 ? "active" : ""}`}
                >
                  <TiMessages className="text-lg" />
                  Messages
                  <span className="ms-auto ms-2 badge-square badge bg-black rounded-circle w-5 h-5 d-flex justify-content-center align-items-center">
                    <span className="text-light text-xs">3</span>
                  </span>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    navigate(ROUTES.PORTFOLIO);
                    dispatch(setSelectedTab({ selectedTab: 'Portfolio' }));
                  }}
                  className={`gap-3 nav-item ${
                    sideMenu?.selectedTab === 'Portfolio' ? 'active' : ''
                  }`}
                >
                  <MdOutlineInsertChart className="text-lg" />
                  Portfolio
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    dispatch(setFavouritesFlag({ isFavouritesTab: true }));
                    navigate(ROUTES.FAVOURITES);
                    dispatch(setSelectedTab({ selectedTab: 'Favourites' }));
                  }}
                  className={`gap-3 nav-item ${
                    sideMenu?.selectedTab === 'Favourites' ? 'active' : ''
                  }`}
                >
                  <MdStarBorder className="text-lg" />
                  Favourites
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    navigate(ROUTES.WALLET);
                    dispatch(setSelectedTab({ selectedTab: 'Wallet' }));
                  }}
                  className={`gap-3 nav-item ${
                    sideMenu?.selectedTab === 'Wallet' ? 'active' : ''
                  }`}
                >
                  <PiPiggyBank className="text-lg" /> Wallet
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    navigate(ROUTES.ACCOUNT);
                    dispatch(setSelectedTab({ selectedTab: 'Account' }));
                  }}
                  className={`gap-3 nav-item ${
                    sideMenu?.selectedTab === 'Account' ? 'active' : ''
                  }`}
                >
                  <BiUser className="text-lg" /> Account
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Sidebar;
