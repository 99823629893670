import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateObject } from '../../../utilities/updateObject';
import { toast } from 'react-toastify';

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  userType: string;
  active: number;
  enableTwoFactorAuth: string;
  appliedBytwoFactorAuth: string;
  twoFactorAuthVerified: string;
  signupIpAddress: string;
  createdAt: string;
  enableNotification: string;
  notificationLanguage: string;
  outsideLinks: any[];
  slug: string;
  __v: number;
  loginIpAddress: string;
  id: string;
  photo?: string;
  coverPhoto?: string;
  password: string;
  passwordConfirm: string;
  userLocation?: string;
  zipcode?: string;
  kycStatus?: string;
  kycReferenceId?: string;
  aboutYourself?: string;
  twoFactorSecretKey?: string;
  uniqueGoogleId?: string;
  uniqueLinkedInId?: string;
  uniqueFacebookId?: string;
  uniqueTwitterId?: string;
  achCustomerId?: string;
  achAccountId?: string; // Assuming you're referencing another Mongoose model
  achAccountStatus?: string;
  isAdmin?: boolean; // Assuming you're referencing another Mongoose model
  accountActivationToken?: string;
  passwordChangedAt?: Date;
  passwordResetToken?: string;
  passwordResetExpires?: Date;
  walletId?: string;
  mangoPayOwnerId?: string;
  mangoPayOwnerWalletId?: string;
  globalSocketId?: string;
  favouriteCompanyIds?: string[];
  isAcceptedTermAndCondition?: boolean;
  stripeClientId?: string;
}
export interface AuthState {
  token: string | null;
  user: User | null;
  error: string | null;
  isSuccessLogin: boolean;
  showResendLink: boolean | null;
  resendEmailMessage: string | null;
  enableTwoFactorAuth: boolean | null;
  isLoading: boolean;
  isVerified: boolean;
  stripeAPIKey: string | null;
  clientId: string | null;
}

const initialState: AuthState = {
  token: null,
  user: null,
  error: null,
  isSuccessLogin: false,
  showResendLink: null,
  resendEmailMessage: null,
  enableTwoFactorAuth: null,
  isLoading: false,
  isVerified: false,
  stripeAPIKey: null,
  clientId: null,
};

const defaultInitialState: AuthState = JSON.parse(JSON.stringify(initialState));

// const authStart = (state: AuthState) => {
//   return updateObject(state, {
//     error: null,
//     isSuccessLogin: true,
//     showResendLink: null,
//     resendEmailMessage: null,
//   });
// };
const authFail = (state: AuthState, action: PayloadAction<{ error: any }>) => {
  toast.error(action.payload?.error?.message);
  return updateObject(state, {
    error: action.payload?.error.message,
    showResendLink: action.payload?.error.showResendLink,
  });
};

const authSuccess = (
  state: AuthState,
  action: PayloadAction<{ token: string }>,
) => {
  return updateObject(state, {
    token: action.payload.token,
    error: null,
    showResendLink: null,
    isSuccessLogin: true,
  });
};
const setStripeAPIKey = (
  state: AuthState,
  action: PayloadAction<{ stripeAPIKey: string }>,
) => {
  return updateObject(state, {
    stripeAPIKey: action.payload.stripeAPIKey,
  });
};

const setClientId = (
  state: AuthState,
  action: PayloadAction<{ clientId: string }>,
) => {
  return updateObject(state, {
    clientId: action.payload.clientId,
  });
};

const setUser = (state: AuthState, action: PayloadAction<{ user: User }>) => {
  return updateObject(state, {
    user: action.payload.user,
  });
};

const updateUser = (
  state: AuthState,
  action: PayloadAction<{ user: Partial<User> }>,
) => {
  return { ...state, user: updateObject(state.user, action.payload.user) };
};

const authClearError = (state: AuthState) => {
  return updateObject(state, {
    error: null,
    isSuccessLogin: false,
    showResendLink: null,
  });
};

const clearTwoFactorLoginError = (state: AuthState) => {
  return updateObject(state, {
    error: null,
  });
};

const logout = () => {
  return defaultInitialState;
};

const loading = (
  state: AuthState,
  action: PayloadAction<{ isLoading: boolean }>,
) => {
  return updateObject(state, {
    isLoading: action.payload.isLoading,
  });
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // authStart,
    authSuccess: authSuccess,
    authClearError,
    authFail,
    clearTwoFactorLoginError,
    logout,
    loading,
    setClientId,
    setStripeAPIKey,
    setUser,
    updateUser,
  },
});

export const {
  // authStart: authStartReducer,
  authSuccess: authSuccessReducer,
  authClearError: authClearErrorReducer,
  authFail: authFailReducer,
  clearTwoFactorLoginError: clearTwoFactorLoginErrorReducer,
  logout: logoutReducer,
  loading: loadingReducer,
  setClientId: setClientIdReducer,
  setStripeAPIKey: setStripeAPIKeyReducer,
  setUser: setUserReducer,
  updateUser: updateUserReducer,
} = authSlice.actions;
export default authSlice.reducer;
