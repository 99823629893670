export const INSTAGRAM_URL = 'https://www.instagram.com/proxxchange/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/propxchange';
export const FACEBOOK_URL =
  'https://www.facebook.com/profile.php?id=61561395594402&mibextid=ZbWKwL';
export const TWITTER_URL = 'https://x.com/propXchange24';
// Chatbot
export const CHATBOT_INTEGRATION_LAMBDA_URL =
  ' https://linqyi2ys7.execute-api.ca-central-1.amazonaws.com/dev/retrieveAndGenerate';
export const ZOHO_INTEGRATION_LAMBDA_URL =
  'https://n5lk30on5d.execute-api.ca-central-1.amazonaws.com';
