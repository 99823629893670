// import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  CardBody,
  Stack,
  Accordion,
} from 'react-bootstrap';
import { MdArrowBackIosNew } from 'react-icons/md';
import { IoLocationSharp } from 'react-icons/io5';
import { IoMdStar, IoMdStarOutline } from 'react-icons/io';

import Slider from 'react-slick';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/type';
import { useEffect, useState } from 'react';
import { getPropertyImages } from '../../services/property/getPropertyImages';
import {
  selectProperty,
  selectPropertyDocs,
  selectPropertyImages,
} from '../../redux/slices/property/propertiesSlice';
import { getPropertyDocs } from '../../services/property/getPropertyDocs';
import {
  setClientIdReducer,
  updateUserReducer,
} from '../../redux/slices/auth/authSlice';
import InvestmentModal from './components/InvestmentModal';
import { updateUserService } from '../../services/user/updateUserService';
import { getPropertyById } from '../../services/property/getPropertyById';
import { HeaderDark } from '../../component';
import { ROUTES } from '../../routes/routes';
import { PropertyDescription } from './PropertyDescription';
import { InvestmentCalculator } from './InvestmentCalculator';
import { LocationSection } from './LocationSection';
import { DocumentSection } from './DocumentSection';
import './PropertyInformation.scss';
import BottomNavbar from '../../component/BottomNavbar/BottomNavbar';
import { formatAmount, formatIRR, formatNumber } from '../util';
import { REACT_APP_AWS_URL } from '../../utilities/envConstants';

const LeftArrow = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className="bg-danger position-absolute translate-middle-y top-1/2 start-3 overlap-40 bg-light w-8 h-8 d-flex justify-content-center align-items-center rounded-pill cursor-pointer"
      onClick={onClick}
    >
      <BiChevronLeft />
    </div>
  );
};

const RightArrow = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className="bg-danger position-absolute translate-middle-y top-1/2 end-3 overlap-40 bg-light w-8 h-8 d-flex justify-content-center align-items-center rounded-pill cursor-pointer"
      onClick={onClick}
    >
      <BiChevronRight />
    </div>
  );
};

function PropertyInformation() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const property = useSelector(
    (state: RootState) => state.properties.selectedProperty,
  );
  const isFavouritesTab = useSelector(
    (state: RootState) => state.properties.isFavouritesTab,
  );
  const selectedPropertyImages = useSelector(
    (state: RootState) => state.properties.selectedPropertyImages,
  );
  const selectedPropertyDocs = useSelector(
    (state: RootState) => state.properties.selectedPropertyDocs,
  );

  const favouriteCompanyIds = useSelector(
    (state: RootState) => state.auth.user?.favouriteCompanyIds,
  );

  // const clientId = useSelector((state: RootState) => state.auth.clientId);
  useEffect(() => {
    // [TODO]
    dispatch(setClientIdReducer({ clientId: 'cus_QDrAB6NI4ZCXaC' }));
    if (id && !property) {
      getPropertyById(id)
        .then((res) => {
          console.log(res);
          dispatch(selectProperty({ index: null, property: res }));
          setInitialInvestmentAmount(
            selectedUnits * Number(res?.pricePerShare),
          );
        })
        .catch(console.log);
    } else {
      setInitialInvestmentAmount(
        selectedUnits * Number(property?.pricePerShare),
      );
    }
  }, [dispatch, id, property]);

  const [isShowCheckOut, setIsShowCheckOut] = useState(false);
  // const [options, setOptions] = useState<any>({});
  const [selectedUnits, setSelectedUnits] = useState(1);
  const clientSecret = useSelector(
    (state: RootState) => state.auth.stripeAPIKey,
  );
  const [propertyDetailsTab, setPropertyDetailsTab] = useState(1);
  const [yearsToHold, setYearsToHold] = useState(5);
  const [initialInvestmentAmount, setInitialInvestmentAmount] = useState(1000);

  const getUserSecretAPIKey = () => {
    setIsShowCheckOut(true);
    // if (clientId)
    //   console.log(NODE_ENV,REACT_APP_API_VERSION)
    //   axios.post(REACT_APP_STRIPE_PRYMENT_INTENT_URL as string,
    //     {
    //       amount: "5000",
    //       currency: "cad", // [TODO](Aryan): use Dynamic amount
    //       clientId
    //     }, {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }).then((res) => {
    //     dispatch(setStripeAPIKeyReducer({ stripeAPIKey: res?.data?.clientSecret }))
    //     setIsShowCheckOut(true)
    //   }).catch((error) => toast.error("something went wrong, try again later"))
  };

  useEffect(() => {
    if (clientSecret) {
      // setOptions({ clientSecret });
    }
  }, [clientSecret]);
  const toggleFavourite = async () => {
    try {
      let companyIds: string[];
      const propertyId = property?.id as string;

      if (favouriteCompanyIds?.includes(propertyId)) {
        // If the propertyId is already in the array, remove it
        companyIds = favouriteCompanyIds.filter((id) => id !== propertyId);
      } else {
        // If the propertyId is not in the array, add it
        companyIds = [...(favouriteCompanyIds || []), propertyId];
      }

      const response = await updateUserService({
        favouriteCompanyIds: companyIds,
      });
      if (response) {
        dispatch(
          updateUserReducer({ user: { favouriteCompanyIds: companyIds } }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isFavourite = favouriteCompanyIds?.includes(property?.id as string);

  useEffect(() => {
    if (property?.id || id) {
      getPropertyImages(property?.id || id)
        .then((res) => {
          dispatch(selectPropertyImages({ images: res }));
        })
        .catch(console.log);
      getPropertyDocs(property?.id || id)
        .then((res) => {
          dispatch(selectPropertyDocs({ docs: res }));
        })
        .catch(console.log);
    }
    //  react-hooks/exhaustive-deps
  }, [property?.id, dispatch, id]);
  const settings = {
    customPaging: function (i) {
      return (
        <div>
          <img
            src={`${REACT_APP_AWS_URL}/${selectedPropertyImages[i].imageUrl}`}
            alt=""
            className="active-image"
          />
        </div>
      );
    },
    dots: true,
    cssEase: 'linear',
    dotsClass: 'slick-dots slick-thumb bottom-8 start-8',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <LeftArrow
        onClick={function (): void {
          throw new Error('Function not implemented.');
        }}
      />
    ),
    nextArrow: (
      <RightArrow
        onClick={function (): void {
          throw new Error('Function not implemented.');
        }}
      />
    ),
  };

  return (
    <div className="pb-14">
      <HeaderDark takeMobileChildren={true}>
        <Container fluid className="position-relative overlap-10">
          <div
            className="hstack gap-1 cursor-pointer"
            onClick={() =>
              navigate(
                `${isFavouritesTab ? ROUTES.FAVOURITES : ROUTES.PROP_OFFERING}`,
              )
            }
          >
            <MdArrowBackIosNew />
            <span
            // to={`${isFavouritesTab ? "/favourites" : "/prop-offering"} `}
            >
              Back
            </span>
          </div>
        </Container>
      </HeaderDark>
      <div className="position-relative w-full h-full overlap-0">
        <div className="position-absolute w-full h-80 bg-tealish-green d-md-block d-none mt-n8"></div>
      </div>

      <Container fluid className="max-w-screen-xl mb-12 px-md-12 px-0">
        <h2 className="d-md-block d-none">Custom Paging</h2>

        {selectedPropertyImages?.length === 1 ? (
          <div className="property-information-slider mb-md-4 overflow-hidden">
            <div className="index-image position-relative">
              <img
                src={`${REACT_APP_AWS_URL}/${selectedPropertyImages[0].imageUrl}`}
                alt=""
                className="object-cover rounded-md-4 property-cover-image"
              />
              <p className="position-absolute top-0 end-0 bg-light px-2 py-1 rounded-pill m-4">
                Villa
              </p>
            </div>
          </div>
        ) : (
          <Slider
            {...settings}
            className="property-information-slider mb-md-4 overflow-hidden"
          >
            {selectedPropertyImages?.map((item, index) => (
              <div key={index} className="index-image position-relative">
                <img
                  src={`${REACT_APP_AWS_URL}/${item.imageUrl}`}
                  alt=""
                  style={{ width: '100%', height: '536px' }}
                  className="object-cover rounded-4"
                />
                <p className="position-absolute top-0 end-0 bg-light px-2 py-1 rounded-pill m-4">
                  Villa
                </p>
              </div>
            ))}
          </Slider>
        )}
        <Row className="gx-0 gy-6">
          <Col lg={8}>
            <Card className="rounded-end-0 h-full">
              <CardBody className="px-4 py-6">
                <Stack direction="horizontal" gap={4}>
                  <div>
                    <h5>{property?.companyId?.companyName}</h5>
                    <p>{property?.companyId?.address}</p>
                  </div>
                  <div>
                    {/*[TBD] Do we need this? */}
                    {/* <div className="d-md-block d-none bg-gray-100 font-semibold hstack gap-1 py-2 px-4 rounded-pill ">
                      <IoLocationSharp className="text-base text-primary" />
                      <p className="text-sm">Vancouver</p>
                    </div> */}
                  </div>
                  <p className="ms-auto">
                    <span className="font-bold">
                      {formatAmount(property?.pricePerShare)}
                    </span>
                    /Unit
                  </p>
                </Stack>
              </CardBody>
              <CardBody className="border-top p-0">
                <Row className="gx-0 gy-0">
                  <Col md={3} xs={6}>
                    <div className="border-end border-bottom p-4">
                      <p className="text-xs mb-1">Property Valuation</p>
                      <h6 className="text-base">
                        {formatAmount(property?.preMoneyValuation)}
                      </h6>
                    </div>
                  </Col>
                  <Col md={3} xs={6}>
                    <div className="border-end border-bottom p-4">
                      <p className="text-xs mb-1">IRR</p>
                      <h6 className="text-base">
                        {formatIRR(property?.annualRateOfReturn)}
                      </h6>
                    </div>
                  </Col>
                  <Col md={3} xs={6}>
                    <div className="border-end border-bottom p-4">
                      <p className="text-xs mb-1">Prop Units Available</p>
                      <h6 className="text-base">
                        {formatNumber(property?.availableShares)}
                      </h6>
                    </div>
                  </Col>
                  <Col md={3} xs={6}>
                    <div className="p-4 border-bottom">
                      <p className="text-xs mb-1">No. of Investors</p>
                      <h6 className="text-base">{property?.totalInvestors}</h6>
                    </div>
                  </Col>
                  <Col md={3} xs={6}>
                    <div className="border-end border-bottom p-4">
                      <p className="text-xs mb-1">Fund Raised</p>
                      <h6 className="text-base">
                        {property?.percentageCompleted}
                      </h6>
                    </div>
                  </Col>
                  <Col md={3} xs={6}>
                    <div className="border-end border-bottom p-4">
                      <p className="text-xs mb-1">Investment Tenure</p>
                      <h6 className="text-base">3-5 years</h6>
                    </div>
                  </Col>
                  <Col md={3} xs={6}>
                    <div className="border-end border-bottom p-4">
                      <p className="text-xs mb-1">Built In</p>
                      <h6 className="text-base">
                        {new Date(
                          property?.companyId?.yearFounded as string,
                        ).getFullYear()}
                      </h6>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="rounded-end-lg-2 rounded-start-lg-0 roun h-full overflow-hidden">
              <CardBody className="px-md-5 py-4 bg-gray-200 hstack ">
                <p>No. of Prop Units</p>
                <div className="ms-auto">
                  <div className="hstack gap-2">
                    <Button
                      variant=""
                      className="btn-square bg-light text-dark h-6 w-10 h-10"
                      onClick={() =>
                        setSelectedUnits((prev) => {
                          if (prev <= 1) return prev;
                          setInitialInvestmentAmount(
                            (prev - 1) * Number(property?.pricePerShare),
                          );
                          return prev - 1;
                        })
                      }
                    >
                      -
                    </Button>
                    <Form.Control
                      value={selectedUnits}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (parseInt(input) > 0) {
                          const unit = parseInt(input);
                          setSelectedUnits(unit);
                          setInitialInvestmentAmount(
                            unit * Number(property?.pricePerShare),
                          );
                        }
                      }}
                      inputMode="numeric"
                      className="w-20"
                      type="text"
                      id="text"
                    />
                    <Button
                      variant=""
                      className="btn-square bg-light text-dark h-6 w-10 h-10"
                      onClick={() =>
                        setSelectedUnits((prev) => {
                          setInitialInvestmentAmount(
                            (prev + 1) * Number(property?.pricePerShare),
                          );
                          return prev + 1;
                        })
                      }
                    >
                      +
                    </Button>
                  </div>
                </div>
              </CardBody>
              <CardBody className="px-md-5 py-4">
                <Row className="gy-2">
                  <Col xs={6}>
                    <p className="text-sm font-semibold">Investment</p>
                  </Col>
                  <Col xs={6}>
                    <p className="text-sm font-bold text-dark text-end">
                      {formatAmount(
                        selectedUnits * Number(property?.pricePerShare),
                      )}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p className="text-sm font-semibold">Fees</p>
                  </Col>
                  <Col xs={6}>
                    <p className="text-sm font-bold text-dark text-end">
                      {/* TODO - Write logic to add fees*/}
                      {formatAmount(0)}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p className="text-sm font-semibold">Total</p>
                  </Col>
                  <Col xs={6}>
                    <p className="text-sm font-bold text-dark text-end">
                      {formatAmount(
                        selectedUnits * Number(property?.pricePerShare),
                      )}
                    </p>
                  </Col>
                </Row>
              </CardBody>
              <CardBody className="px-md-5 py-2 border-top">
                <Row className="gx-3">
                  <Col>
                    <Button
                      onClick={() => getUserSecretAPIKey()}
                      variant="primary"
                      className="w-full rounded-pill "
                    >
                      Invest Now
                    </Button>
                    <InvestmentModal
                      show={isShowCheckOut}
                      handleClose={() => setIsShowCheckOut(false)}
                    />
                    {/* {options?.clientSecret && <Elements stripe={stripePromise} options={options}>
                        <CheckoutModal show={isShowCheckOut} onHide={()=> setIsShowCheckOut(false)} />
                      </Elements>} */}
                  </Col>
                  <Col className="col-auto">
                    <Button
                      onClick={() => toggleFavourite()}
                      variant=""
                      className="btn-square rounded-circle border"
                    >
                      {isFavourite ? (
                        <IoMdStar className="text-md text-warning" /> // Golden filled star
                      ) : (
                        <IoMdStarOutline className="text-md text-dark" /> // Outline star
                      )}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="max-w-screen-xl px-md-12 px-0">
        <ul className="position-sticky overlap-10 property-menu list-unstyled nav gap-xxl-8 gap-4 mb-xxl-8 py-2 bg-white d-none d-md-flex">
          <li
            className={`property-description ${
              propertyDetailsTab === 1 ? 'active' : ''
            }`}
          >
            <a
              className="text-sm font-semibold"
              onClick={() => setPropertyDetailsTab(1)}
              href="#property-description"
            >
              Property Description
            </a>
          </li>
          <li
            className={`investment-calculator ${
              propertyDetailsTab === 2 ? 'active' : ''
            }`}
          >
            <a
              className="text-sm font-semibold"
              onClick={() => setPropertyDetailsTab(2)}
              href="#investment-calculator"
            >
              Investment Calculator
            </a>
          </li>
          <li
            className={`location ${propertyDetailsTab === 3 ? 'active' : ''}`}
          >
            <a
              className="text-sm font-semibold"
              onClick={() => setPropertyDetailsTab(3)}
              href="#location"
            >
              Location
            </a>
          </li>
          <li
            className={`documents ${propertyDetailsTab === 4 ? 'active' : ''}`}
          >
            <a
              className="text-sm font-semibold"
              onClick={() => setPropertyDetailsTab(4)}
              href="#documents"
            >
              Documents
            </a>
          </li>
        </ul>
        <div className="d-none d-md-block">
          <section className="pt-6" id="property-description">
            <PropertyDescription property={property} />
          </section>
          <section className="pt-6" id="investment-calculator">
            <InvestmentCalculator
              initialInvestmentAmount={initialInvestmentAmount}
              setInitialInvestmentAmount={setInitialInvestmentAmount}
              yearsToHold={yearsToHold}
              setYearsToHold={setYearsToHold}
              property={property}
            />
          </section>
          <section className="pt-6" id="location">
            <LocationSection property={property} />
          </section>
          <section className="pt-6" id="documents">
            <DocumentSection selectedPropertyDocs={selectedPropertyDocs} />
          </section>
        </div>
        <div className="d-block d-md-none border-top border-8 border-gray-200">
          <Accordion defaultActiveKey="0" className="accordion-arrow mb-12">
            <Accordion.Item eventKey={'property-description'}>
              <Accordion.Header className="px-5">
                Property Description
              </Accordion.Header>
              <Accordion.Body>
                <PropertyDescription property={property} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={'investment-calculator'}>
              <Accordion.Header className="px-5">
                Investment Calculator
              </Accordion.Header>
              <Accordion.Body>
                <InvestmentCalculator
                  initialInvestmentAmount={initialInvestmentAmount}
                  setInitialInvestmentAmount={setInitialInvestmentAmount}
                  yearsToHold={yearsToHold}
                  setYearsToHold={setYearsToHold}
                  property={property}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={'location'}>
              <Accordion.Header className="px-5">Location</Accordion.Header>
              <Accordion.Body>
                <LocationSection property={property} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={'documents'}>
              <Accordion.Header className="px-5">Documents</Accordion.Header>
              <Accordion.Body>
                <DocumentSection selectedPropertyDocs={selectedPropertyDocs} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
      <BottomNavbar />
    </div>
  );
}

export default PropertyInformation;
