import { useDispatch, useSelector } from 'react-redux';
import { Card, Container } from 'react-bootstrap';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { RootState } from '../../redux/store/type';
import { useEffect } from 'react';
import { getAllInvestments } from '../../services/portfolio/getAllInvestments';
import { HeaderDark } from '../../component';
import './Portfolio.scss';
import { convertNumber } from '../../utilities/helper';
import BottomNavbar from '../../component/BottomNavbar/BottomNavbar';
import { setSelectedTab } from '../../redux/slices/sideMenu/sideMenuSlice';

Chart.register(CategoryScale);

export const Portfolio = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch();
  dispatch(setSelectedTab({ selectedTab: 'Portfolio' }));

  useEffect(() => {
    getAllInvestments(user?.id as string)
      .then((res) => {
        console.log(res);
      })
      .catch(console.log);
  }, []);

  const investmentValues = [
    {
      title: 'Current Portfolio value',
      value: 'C$ 1.5M',
      diff: -3.15,
      updated: '2 days ago',
    },
    {
      title: 'Total Invested value',
      value: 'C$ 700K',
      diff: 12.5,
      updated: '2 days ago',
    },
    {
      title: 'Current Portfolio value',
      value: 'C$ 800K',
      diff: 103.24,
      updated: '2 days ago',
    },
  ];

  const holdings = [
    {
      name: 'Harmony Heights',
      diff: -11.15,
      total: '10k',
      diff_val: 1100,
    },
    {
      name: 'Angel Heights',
      diff: 3.15,
      total: '5k',
      diff_val: 200,
    },
    {
      name: 'Harmony Heights',
      diff: -48.15,
      total: '3k',
      diff_val: 1100,
    },
    {
      name: 'Harmony Heights',
      diff: 91.15,
      total: '21k',
      diff_val: 19000,
    },
  ];

  const portfolioBreakdown = [
    {
      entity: 'Income Generator',
      percentage: 28,
      value: 420000,
    },
    {
      entity: 'Asset Builder',
      percentage: 60,
      value: 900000,
    },
    {
      entity: 'Wealth Creator',
      percentage: 12,
      value: 180000,
    },
  ];
  const chartColor = ['#812ad866', '#DEDEDE', '#812AD8'];

  const chartData = {
    labels: portfolioBreakdown.map((p) => p.entity),
    datasets: [
      {
        label: '',
        data: portfolioBreakdown.map((p) => p.percentage),
        backgroundColor: chartColor,
        borderWidth: 2,
      },
    ],
  };
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '65%',
    radius: 100,
  };

  return (
    <div className="pb-14">
      <HeaderDark>
        <h5 className="font-bolder mx-4">Portfolio</h5>
      </HeaderDark>
      <div className="position-relative w-full h-full overlap-0">
        <div className="position-absolute w-full h-80 bg-gray-200 mt-0"></div>
      </div>
      <Container fluid className="max-w-screen-l my-4 portfolio-container">
        <div className="py-0 ms-auto d-md-flex gap-4 mb-4 justify-content-center">
          {investmentValues.map((c) => (
            <Card className="shadow-lg my-2">
              <Card.Body className="p-5">
                <Card.Text className="text-xs font-bold">
                  {c.title.toUpperCase()}
                </Card.Text>
                <div className="py-0 ms-auto d-flex gap-12 justify-space-between">
                  <Card.Text className="font-bold text-dark card-number">
                    {c.value}
                  </Card.Text>
                  <Card.Text
                    className={`text-sm align-self-center ${
                      c.diff < 0 ? 'text-danger' : 'text-success'
                    }`}
                  >
                    {c.diff < 0 ? (
                      <AiFillCaretDown className="mx-1" />
                    ) : (
                      <AiFillCaretUp className="mx-1" />
                    )}
                    {Math.abs(c.diff)}%
                  </Card.Text>
                </div>
                <Card.Text className="text-xs">
                  Last Updated {c.updated}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>

        <div className="py-0 ms-auto d-md-flex gap-4 justify-space-between">
          <Card className="shadow-lg">
            <Card.Body className="p-5">
              <div className="py-0 mb-6 ms-auto d-flex gap-12 justify-space-between">
                <Card.Text className="text-sm">Holdings</Card.Text>
                <Card.Text className="text-sm">Total P&L</Card.Text>
              </div>
              {holdings.map((h) => (
                <div className="mb-6">
                  <div className="py-0 ms-auto d-flex gap-12 justify-space-between">
                    <Card.Text className="font-bold text-dark text-sm">
                      {h.name}
                    </Card.Text>
                    <Card.Text
                      className={`text-sm align-self-center ${
                        h.diff < 0 ? 'text-danger' : 'text-success'
                      }`}
                    >
                      {h.diff < 0 ? (
                        <AiFillCaretDown className="mx-1" />
                      ) : (
                        <AiFillCaretUp className="mx-1" />
                      )}
                      {Math.abs(h.diff)}%
                    </Card.Text>
                  </div>
                  <div className="py-0 ms-auto d-flex gap-12 justify-space-between">
                    <Card.Text className="font-bold text-sm">
                      C$ {h.total}
                    </Card.Text>
                    <Card.Text
                      className={`text-sm align-self-center ${
                        h.diff < 0 ? 'text-danger' : 'text-success'
                      }`}
                    >
                      C$ {h.diff_val}
                    </Card.Text>
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card>
          <Card className="shadow-lg my-2">
            <Card.Body className="p-5">
              <Card.Text className="mb-4 text-xs font-bold">
                PORTFOLIO BREAKDOWN
              </Card.Text>
              <div
                className="py-0 mb-6 ms-auto d-flex justify-content-center"
                style={{ maxHeight: '300px' }}
              >
                <Doughnut data={chartData} options={chartOptions} />
              </div>
              <div className="py-0 ms-auto d-flex flex-wrap">
                {portfolioBreakdown.map((p, i) => (
                  <div
                    className="mb-6 ps-4"
                    style={{
                      minWidth: '50%',
                      borderLeft: `3px ${chartColor[i]} solid`,
                    }}
                  >
                    <Card.Text className="font-bold text-sm">
                      {p.entity}
                    </Card.Text>
                    <Card.Text className={`text-sm text-dark`}>
                      C$ {convertNumber(p.value)} ({p.percentage}%)
                    </Card.Text>
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
      <BottomNavbar />
    </div>
  );
};
