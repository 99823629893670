import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateObject } from '../../../utilities/updateObject';

interface Option {
  label: string;
  value: string;
}

interface SuitabilityQuestionnaireDocument {
  questionNumber: number;
  question: string;
  info?: string;
  type: 'radio' | 'select' | 'flags';
  options: Option[];
  multiSelect?: boolean;
  placeholder?: string;
  disclaimer?: string;
}

export interface SuitabilityQuestionnaireState {
  isSuccessMessage: boolean;
  errorMessage: string | null;
  errors: string | null;
  isLoading: boolean;
  questionnaireData: SuitabilityQuestionnaireDocument[]; // You might want to define a specific type for questionnaire data
  message: string | null;
}

const initialState: SuitabilityQuestionnaireState = {
  isSuccessMessage: false,
  errorMessage: null,
  errors: null,
  isLoading: false,
  questionnaireData: [],
  message: null,
};

const questionnaireSuccess = (
  state: SuitabilityQuestionnaireState,
  action: PayloadAction<{ successMessage: string; questionnaireData: any[] }>,
) => {
  console.log(action.payload);
  return updateObject(state, {
    isSuccessMessage: true,
    errorMessage: null,
    errors: null,
    isLoading: false,
    message: action.payload?.successMessage,
    questionnaireData: action.payload?.questionnaireData,
  });
};

const loading = (
  state: SuitabilityQuestionnaireState,
  action: PayloadAction<{ isLoading: boolean }>,
) => {
  return updateObject(state, {
    isLoading: action.payload.isLoading,
  });
};

const questionnaireSlice = createSlice({
  name: 'suitabilityQuestionnaire',
  initialState,
  reducers: {
    questionnaireSuccess,
    loading,
  },
});

export const {
  questionnaireSuccess: questionnaireSuccessReducer,
  loading: loadingReducer,
} = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
