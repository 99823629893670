import axiosObj from 'axios';
import {
  REACT_APP_API_URL,
  REACT_APP_API_VERSION,
} from '../utilities/envConstants';

const instance = axiosObj.create({
  baseURL: `${REACT_APP_API_URL}/${REACT_APP_API_VERSION}/`,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token_key');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['Content-Type'] = 'application/json';
  return config;
});

export { instance as axios };
