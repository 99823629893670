import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Stack,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { IoChevronDownOutline } from 'react-icons/io5';
import { Home_1, Home_2, Home_3 } from '../../assets/image';
import { RiTimerLine } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ROUTES } from '../../routes/routes';
import { useTranslation } from 'react-i18next';

function PropertyCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      className="border-bottom-card border border-2 rounded-5 border-dark"
      style={{ backgroundColor: '#FBF8FF' }}
    >
      <Card.Img
        className="h-4xl-auto h-72 "
        style={{
          borderTopLeftRadius: '1.35rem',
          borderTopRightRadius: '1.35rem',
        }}
        variant="top"
        src={props.image}
      />
      <CardBody className="px-7 py-4 rounded-4">
        <h4 className="mb-3">{props.title}</h4>
        <span className="text-dark">{props.description}</span>
        <div className="border-top pt-3 mt-3">
          <Row className="align-items-center">
            <Col xs={7}>
              <div className="hstack gap-2">
                <RiTimerLine className="text-primary h2" />{' '}
                <h4 className="text-2xl">{props.investmentTenure}</h4>
              </div>
              <p>Investment Tenure</p>
            </Col>
            <Col xs={5} className="text-end">
              <Button
                variant="dark"
                onClick={() => navigate(ROUTES.PROP_OFFERING)}
                className="h-12 rounded-pill px-5 px-md-10 text-nowrap"
              >
                {t('home.invest_now')}
              </Button>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
}

function TopSection(props) {
  const { t } = useTranslation();
  const intervalRef = useRef<any>();
  const [activeKey, setActiveKey] = useState('income-generator');
  const tabs = ['income-generator', 'wealth-creator', 'olo'];

  const resetTimer = () => {
    clearInterval(intervalRef?.current);
    intervalRef.current = setInterval(() => {
      setActiveKey((prevKey) => {
        const nextIndex = getNextIndex(prevKey);
        return tabs[nextIndex];
      });
    }, 10000);
  };

  const getNextIndex = (prevKey) => {
    const currentIndex = tabs.indexOf(prevKey);
    const nextIndex = (currentIndex + 1) % tabs.length;
    return nextIndex;
  };

  useEffect(() => {
    resetTimer();
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    resetTimer();
  }, [activeKey]);

  return (
    // add border-grandient class in blow division to add border back
    <div className="mx-md-16 justify-content-center px-xs-5 px-md-20 py-md-24 px-5 py-10">
      <Row className="gy-6 gap-20">
        <Col md={6} className="align-self-center">
          <h1 className="display-3 mb-3 font-regular home-title-font">
            <span className="font-light">
              {t('home.first_section.invest_in_real_estate')}
            </span>
            <br />
            <span className="font-bolder">
              {t('home.first_section.fraction_by_fraction')}
            </span>
          </h1>
          <p className="mb-8 mb-4xl-14 mb-lg-6 text-lg text-dark">
            {t('home.first_section.explore_earn_invest')}
          </p>
          <p className="mb-8 mb-xl-16 mb-4xl-32 text-md-sm text-dark">
            Start investing in real estate with
            <span className="font-bolder"> as little as $10. </span>
            Explore various investment options and build a
            <span className="font-bolder"> diverse portfolio </span>
            tailored to your financial goals.
          </p>
          <Stack direction="horizontal" gap={{ xs: 6, sm: 8, md: 10 }}>
            <Button
              variant="primary"
              onClick={() => props.scrollToTarget()}
              className="rounded-pill btn-lg px-8 mb-8"
            >
              {t('home.first_section.learn_more')}
              <FaArrowRight className="text-sm ms-2" />
            </Button>
          </Stack>
          <div className="d-flex d-md-none mt-5 justify-content-center align-items-center">
            <a href="#scroll-down" className="text-dark font-bold">
              {t('home.first_section.scroll_down')}
            </a>
            <IoChevronDownOutline className="ms-2 text-dark" />
          </div>
        </Col>
        <Col id="scroll-down" className="d-flex flex-row-reverse">
          <div className="ml-auto border-0 position-relative homepage-tab">
            <Tabs
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k as string)}
              transition={false}
              id="noanim-tab-example"
              className={`mb-4xl-8 mb-1 justify-content-center tabs-type-2 ${
                (getNextIndex(activeKey) === 0 ||
                  getNextIndex(activeKey) === 2) &&
                'tab-active-first'
              } ${
                getNextIndex(activeKey) === 0 && 'tab-active-second'
              } row-cols-sm-3 gx-4`}
            >
              <Tab eventKey="income-generator" title="Income Generator">
                <PropertyCard
                  title="Income Generator"
                  image={Home_1}
                  description="Income Generator will help you to generate
                  Secondary Income. Return starts as early as the Offering is Closed."
                  investmentTenure="Open"
                />
              </Tab>
              <Tab eventKey="wealth-creator" title="Wealth Creator">
                <PropertyCard
                  title="Wealth Creator"
                  image={Home_2}
                  description="Pre-construction projects help identify issues
                          early, save costs, and improve planning, leading to
                          long-term investment benefits and wealth growth."
                  investmentTenure="3-4 Years"
                />
              </Tab>
              <Tab eventKey="olo" title="Asset Builder">
                <PropertyCard
                  title="Asset Builder"
                  image={Home_3}
                  description="Approved Lots (Development ready) Lower mainlands
                          most desired land investment opportunity, be a
                          fractional land owner and see your investment
                          turning gold."
                  investmentTenure="1-5 Years"
                />
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TopSection;
