import { Button, Row, Col, Image, Form, Spinner } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import {
  Facebook2,
  Instagram,
  LinkedIn,
  Logo,
  ErrorFill,
  Twitter,
} from '../../assets/image';
import { useState } from 'react';
import './LaunchSoon.scss';
import { subscribeToMailingList } from '../../services/zoho/subscribeToMailingList';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TWITTER_URL,
} from '../../constants';

function LaunchSoon() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isValidEmail = (email: string): boolean => {
    // Regular expression for validating an email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async () => {
    setLoading(true); // Optional: Set loading state to true

    try {
      await subscribeToMailingList(email);
      toast.success('Successfully subscribed!'); // Show success message
      setEmail(''); // Clear the email input
    } catch (error) {
      toast.error('Failed to subscribe. Please try again.'); // Show error message
    } finally {
      setLoading(false); // Optional: Set loading state to false
    }
  };

  return (
    <>
      <div>
        <Row className="h-screen">
          <Col md={6} xs={0} className="d-md-block d-none">
            <div className="banner-background"></div>
          </Col>
          <Col
            md={6}
            xs={12}
            className="text-center d-flex flex-column justify-content-between py-10"
          >
            <Image
              alt=""
              className="mt-10"
              src={Logo}
              width="200"
              style={{ maxWidth: '200px', cursor: 'pointer', margin: '0 auto' }}
            />
            <div className="align-self-center ps-6 pe-6 ps-sm-0">
              <div className="mb-20">
                <div className="launch-heading text-dark text">
                  Launching <b>Soon!</b>
                </div>
                <p>Building Wealth, One Fraction at a Time – Coming Soon</p>
              </div>
              <div>
                <Row>
                  <Form.Label className="text-gray-400 text-start w-full">
                    Get notified when we launch
                  </Form.Label>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form>
                      <Form.Group controlId="floatingInput1">
                        <Form.Control
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          value={email}
                          className={
                            emailError
                              ? 'invalid-border invalid-border-shadow'
                              : 'form-control'
                          }
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                          onBlur={(event) => {
                            setEmailError(
                              !event.target.value ||
                                event.target.value === '' ||
                                !isValidEmail(event.target.value),
                            );
                          }}
                        />
                        {emailError && (
                          <p className="invalid-color mt-1 d-flex align-item-center">
                            <Image src={ErrorFill} className="mx-2" />
                            Please enter valid email
                          </p>
                        )}
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col sm={4} className="mt-sm-0 text-start">
                    <Button
                      disabled={emailError}
                      variant="primary"
                      onClick={() => handleSubmit()}
                      className="rounded-pill btn-lg py-2 mt-4 mt-md-0 font-semibold text-sm"
                      style={{ width: 'max-content' }}
                    >
                      {loading ? (
                        <span>
                          <Spinner animation="border" size="sm" />
                        </span>
                      ) : (
                        <span>
                          Subscribe <FaArrowRight className="ms-1" />
                        </span>
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              <p>Follow us on</p>
              <div className="d-flex gap-3 justify-content-center mt-2">
                <a
                  href={INSTAGRAM_URL}
                  target="_blank"
                  className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 border border-gray-200"
                >
                  <Image src={Instagram} width={14} height={18} />
                </a>
                <a
                  href={TWITTER_URL}
                  target="_blank"
                  className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 border border-gray-200"
                >
                  <Image src={Twitter} width={14} height={18} />
                </a>
                <a
                  href={LINKEDIN_URL}
                  target="_blank"
                  className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 border border-gray-200"
                >
                  <Image src={LinkedIn} width={24} height={18} />
                </a>
                <a
                  href={FACEBOOK_URL}
                  target="_blank"
                  className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 border border-gray-200"
                >
                  <Image src={Facebook2} width={8} height={18} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LaunchSoon;
