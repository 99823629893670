import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';

// Initialize i18next
i18n.use(initReactI18next).init({
  lng: 'en', // default language
  fallbackLng: 'en', // fallback language if the selected one is not available
  debug: true, // for debugging purposes, remove in production
  ns: ['translation'],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources: {
    en: {
      translation: enTranslations,
    },
  },
});

export default i18n;
