import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Doc, DocumentData, ImageData } from '../../../services/property/types';

export interface PropertiesState {
  error: string | null;
  isLoading: boolean;
  properties: Doc[];
  isFavouritesTab: boolean;
  selectedPropertyIndex: number | null;
  selectedPropertyImages: ImageData[];
  selectedPropertyDocs: DocumentData[];
  selectedProperty: Doc | undefined;
}

const initialState: PropertiesState = {
  error: null,
  isLoading: false,
  properties: [],
  isFavouritesTab: false,
  selectedPropertyIndex: null,
  selectedProperty: undefined,
  selectedPropertyImages: [],
  selectedPropertyDocs: [],
};

const defaultInitialState: PropertiesState = JSON.parse(
  JSON.stringify(initialState),
);

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    fetchPropertiesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchPropertiesSuccess(
      state,
      action: PayloadAction<{ properties: Doc[] }>,
    ) {
      state.isLoading = false;
      state.properties = action.payload.properties;
      state.error = null;
    },
    fetchPropertiesFail(state, action: PayloadAction<{ error: string }>) {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    selectProperty(
      state,
      action: PayloadAction<{ index: number | null; property?: Doc }>,
    ) {
      state.selectedPropertyIndex = action.payload.index;
      if (action.payload.index) {
        state.selectedProperty = state.properties[action.payload.index];
      } else {
        //  @typescript-eslint/no-unused-expressions
        state.selectedProperty = action?.payload?.property;
      }
    },
    selectPropertyImages(
      state,
      action: PayloadAction<{ images: ImageData[] }>,
    ) {
      state.selectedPropertyImages = action.payload.images;
    },
    setFavouritesFlag(
      state,
      action: PayloadAction<{ isFavouritesTab: boolean }>,
    ) {
      state.isFavouritesTab = action.payload.isFavouritesTab;
    },
    selectPropertyDocs(state, action: PayloadAction<{ docs: DocumentData[] }>) {
      state.selectedPropertyDocs = action.payload.docs;
    },
    clearSelectedProperty(state) {
      state.selectedPropertyIndex = null;
      state.selectedProperty = undefined;
    },
    resetPropertiesState() {
      return defaultInitialState;
    },
  },
});

export const {
  fetchPropertiesStart,
  fetchPropertiesSuccess,
  fetchPropertiesFail,
  selectProperty,
  clearSelectedProperty,
  resetPropertiesState,
  selectPropertyDocs,
  selectPropertyImages,
  setFavouritesFlag,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
