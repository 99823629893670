import CryptoJS from 'crypto-js';
import { REACT_APP_ENC_DEC_IV, REACT_APP_ENC_DEC_KEY } from './envConstants';
// Below commented code is for create new Key & IV for future.

// var key = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
// var iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");

var key = {
  words: CryptoJS.enc.Base64.parse(REACT_APP_ENC_DEC_KEY as string)?.words,
  sigBytes: CryptoJS.enc.Base64.parse(REACT_APP_ENC_DEC_KEY as string).sigBytes,
};
var iv = {
  words: CryptoJS.enc.Base64.parse(REACT_APP_ENC_DEC_IV as string).words,
  sigBytes: CryptoJS.enc.Base64.parse(REACT_APP_ENC_DEC_IV as string).sigBytes,
};

/* This function is used to encrypt original string. */
export const encryptData = (decrypted) => {
  const encrypted = CryptoJS.AES.encrypt(
    decrypted,
    key as any,
    {
      iv,
    } as any,
  ).toString();
  return encrypted;
};

/* This function is used to decrypt encrypted string. */
export const decryptedData = (encrypted) => {
  if (encrypted) {
    /* This line is for convert encrypted string in bytes. */
    const bytes = CryptoJS.AES.decrypt(
      encrypted.toString(),
      key as any,
      {
        iv,
      } as any,
    );

    /* This line is for convert bytes into original string. */
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    /* This line return decrypted string if its encrypted else it return same string. */
    return decrypted ? decrypted : encrypted;
  } else {
    return '';
  }
};
