import { toast } from 'react-toastify';
import { axios } from '../../axios/AxiosConfig';
import { EXTRAS_IMAGE_API } from '../apiEndPoints';
import { ImageData } from './types';

export const getPropertyImages = async (equityId): Promise<ImageData[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${EXTRAS_IMAGE_API}${equityId}/front`);
      resolve(response.data?.data?.data);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
