import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type SideMenus =
  | 'Prop Offerings'
  | 'Portfolio'
  | 'Wallet'
  | 'Account'
  | 'Favourites'
  | 'Messages';

type SideMenuTab = { title: SideMenus; active: boolean };
export interface SideMenuState {
  selectedTab: SideMenus;
  sideMenuTabs: SideMenuTab[];
}

const initialState: SideMenuState = {
  selectedTab: 'Prop Offerings',
  sideMenuTabs: [
    { title: 'Account', active: true },
    { title: 'Favourites', active: true },
    { title: 'Messages', active: true },
    { title: 'Portfolio', active: true },
    { title: 'Prop Offerings', active: true },
    { title: 'Wallet', active: true },
  ],
};

const sideMenuSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setSelectedTab(state, action: PayloadAction<{ selectedTab: SideMenus }>) {
      state.selectedTab = action.payload.selectedTab;
    },
  },
});

export const { setSelectedTab } = sideMenuSlice.actions;

export default sideMenuSlice.reducer;
