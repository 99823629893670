import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateObject } from '../../../utilities/updateObject';

export interface RegistrationState {
  isSuccessMessage: boolean;
  errorMessage: string | null;
  errors: string | null;
  isLoading: boolean;
  userTypes: any[];
  newsletterSubscribe: any[];
  message: string | null;
}

const initialState: RegistrationState = {
  isSuccessMessage: false,
  errorMessage: null,
  errors: null,
  isLoading: false,
  userTypes: [],
  newsletterSubscribe: [],
  message: null,
};

const registrationSuccess = (
  state: RegistrationState,
  action: PayloadAction<{ successMessage: string }>,
) => {
  return updateObject(state, {
    isSuccessMessage: true,
    errorMessage: null,
    errors: null,
    isLoading: false,
    message: action.payload?.successMessage,
  });
};

const loading = (
  state: RegistrationState,
  action: PayloadAction<{ isLoading: boolean }>,
) => {
  return updateObject(state, {
    isLoading: action.payload.isLoading,
  });
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    registrationSuccess,
    loading,
  },
});

export const {
  registrationSuccess: registrationSuccessReducer,
  loading: loadingReducer,
} = registrationSlice.actions;
export default registrationSlice.reducer;
