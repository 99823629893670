export const ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  ACTIVATE_ACCOUNT: '/activate-account',
  ACCOUNT: '/account',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset-password',
  SUITABILITY_QUESTIONNAIRE: '/suitability-questionnaire',
  PROP_OFFERING: '/prop-offering',
  FAQ: '/faq',
  FAVOURITES: '/favourites',
  WALLET: '/wallet',
  PORTFOLIO: '/portfolio',
  DASHBOARD: '/dashboard',
  OUR_TEAM: '/our-team',
  ABOUT_US: '/aboutus',
  CONTACT_US: '/contact-us',
  TERMS_OF_USE: '/terms-of-use',
  COOKIES_POLICY: '/cookies-policy',
  PRIVACY_POLICY: '/privacy-policy',
};
