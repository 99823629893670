import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateObject } from '../../../utilities/updateObject';

export interface PaymentState {
  stripeAPIKey: string | null;
  options: {
    clientId: string | null;
  };
}

const initialState: PaymentState = {
  stripeAPIKey: null,
  options: {
    clientId: null,
  },
};

const setStripeAPIKey = (
  state: PaymentState,
  action: PayloadAction<{ stripeAPIKey: string }>,
) => {
  return updateObject(state, {
    stripeAPIKey: action.payload.stripeAPIKey,
  });
};

const setClientId = (
  state: PaymentState,
  action: PayloadAction<{ clientId: string }>,
) => {
  return updateObject(state, {
    options: {
      ...state.options,
      clientId: action.payload.clientId,
    },
  });
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setStripeAPIKey,
    setClientId,
  },
});

export const {
  setStripeAPIKey: setStripeAPIKeyReducer,
  setClientId: setClientIdReducer,
} = paymentSlice.actions;

export default paymentSlice.reducer;
