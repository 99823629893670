import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateObject } from '../../../utilities/updateObject';

export interface UserResponse {
  questionNumber: number;
  answer: string[];
}

export interface UserResponseSQState {
  responses: UserResponse[];
  errorMessage: string | null;
  isSuccess: boolean;
  isLoading: boolean;
}

const initialState: UserResponseSQState = {
  responses: [],
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const userResponseSQSlice = createSlice({
  name: 'userResponseSQ',
  initialState,
  reducers: {
    addResponse: (state, action: PayloadAction<UserResponse>) => {
      return updateObject(state, {
        responses: [...state.responses, action.payload],
        isSuccess: true,
        errorMessage: null,
      });
    },
    removeResponse: (state, action: PayloadAction<number>) => {
      return updateObject(state, {
        responses: state.responses.filter(
          (response) => response.questionNumber !== action.payload,
        ),
      });
    },
    updateResponse: (state, action: PayloadAction<UserResponse>) => {
      const currentState = JSON.parse(
        JSON.stringify(state),
      ) as UserResponseSQState;
      const index = currentState.responses.findIndex(
        (response) => response.questionNumber === action.payload.questionNumber,
      );
      if (index !== -1) {
        currentState.responses[index] = action.payload;
      } else {
        currentState.responses.push(action.payload);
      }
      return updateObject(state, currentState);
    },
    loading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      return updateObject(state, {
        isLoading: action.payload.isLoading,
      });
    },
    setError: (state, action: PayloadAction<string>) => {
      return updateObject(state, {
        errorMessage: action.payload,
        isSuccess: false,
      });
    },
    reset: () => initialState,
  },
});

export const {
  addResponse,
  removeResponse,
  updateResponse,
  loading,
  setError,
  reset,
} = userResponseSQSlice.actions;
export default userResponseSQSlice.reducer;
