import Sidebar from '../component/SideBar/SideBar';
import { HeaderDark } from '../component';

function Layout({ children }) {
  return (
    <main className="d-flex flex-column flex-lg-row h-lg-full">
      <Sidebar />
      <div className="flex-lg-1 right-side-body h-lg-screen overflow-y-lg-auto vstack">
        <div className="flex-fill overlap-10 ">{children}</div>
      </div>
    </main>
  );
}
export default Layout;
